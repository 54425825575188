import { Injectable } from '@angular/core';
import { ApiRestService, BaseSiteRestService, SearchResult } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import {
  MilestoneInvoice,
  InvoiceSearch,
  PerformanceMilestones,
  PerformanceMilestonesEmea,
} from '../../model/core/milestone';

@Injectable({
  providedIn: 'root',
})
export class MilestoneRestService extends BaseSiteRestService {
  constructor(protected apiRestService: ApiRestService) {
    super(environment.api.core.baseUrl, environment.api.core.path.member, 'lenovo/boost/performance');
  }

  getMilestones(): Observable<PerformanceMilestones | PerformanceMilestonesEmea> {
    return this.apiRestService.get<PerformanceMilestones>(`${this.memberBaseUrl}`);
  }

  getBrands(): Observable<string[]> {
    return this.apiRestService.get<string[]>(`${this.memberBaseUrl}/brands`);
  }

  searchInvoice(invoiceSearch: InvoiceSearch): Observable<SearchResult<MilestoneInvoice>> {
    return this.apiRestService.post<SearchResult<MilestoneInvoice>>(
      `${this.memberBaseUrl}/invoices-search`,
      invoiceSearch
    );
  }
}
