import { Component } from '@angular/core';
import { filter } from 'rxjs';
import { UserPartnerType } from 'src/app/core/enum/user-partner-type.enum';

import { MilestoneStoreService } from '../../../core/store/milestone-store.service';

@Component({
  selector: 'app-company-performance',
  templateUrl: './company-performance.component.html',
  styleUrls: ['./company-performance.component.scss'],
})
export class CompanyPerformanceComponent {
  UserPartnerType = UserPartnerType;

  performance$ = this.milestoneStore.performanceMilestones$.pipe(filter(Boolean));

  constructor(private milestoneStore: MilestoneStoreService) {}
}
