<div class="modal-header">
  <h1 class="modal-title" id="modal-basic-title">
    {{ 'Welcome to Lenovo 360 Boost' | translate }}
  </h1>
</div>
<div class="modal-body">
  <div class="prompt">
    {{ 'Please accept the' | translate }}
    <a href="{{ termsAndConditionsUrl$ | async }}" target="_blank">{{ 'terms and conditions' | translate }}</a>
    {{ 'to access your loyalty program' | translate }}.
  </div>

  <div class="w-100 mt-4">
    <form class="d-flex align-items-center justify-content-center flex-column">
      <div class="form-row w-100">
        <div class="d-flex align-items-center terms-form">
          <input type="checkbox" id="tnCs" [(ngModel)]="tnCsChecked" [ngModelOptions]="{ standalone: true }" />
          <label for="tnCs">
            {{ 'I have read and agreed to the' | translate }}
            <a href="{{ termsAndConditionsUrl$ | async }}" target="_blank">{{ 'Lenovo Boost Terms and Conditions' | translate }}</a>
          </label>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="onSubmit()">{{ 'Accept' | translate }}</button>
</div>
