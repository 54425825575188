import { Component, OnInit } from '@angular/core';
import { ContentStoreService } from '@motivforce/mx-library-angular';
import { filter, take } from 'rxjs';

import { UserStoreService } from '../../../store/user-store.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: string;

  termsAndConditionsUrl$ = this.userStore.termsAndConditionsUrl$;
  privacyUrl: string;

  constructor(private userStore: UserStoreService, private contentStore: ContentStoreService) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();

    this.contentStore.siteSettings$.pipe(filter(Boolean), take(1)).subscribe((siteSettings) => {
      this.privacyUrl = siteSettings.siteSettings.privacyUrl;
    });
  }
}
