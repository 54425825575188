<footer class="footer">
  <div class="h-100 container">
    <div class="h-100 row">
      <div class="col-8">
        <div
          class="h-100 d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-sm-center"
        >
          <div class="footer-item">
            <fa-icon class="me-1" [icon]="['fal', 'copyright']" size="xs"></fa-icon>
            <span>{{ this.year }} Lenovo Firm Rewards</span>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <div class="footer-item">
              <a
                href="{{ termsAndConditionsUrl$ | async }}"
                target="_blank"
                >{{ 'Terms & Conditions' | translate }}
              </a>
            </div>
            <div class="footer-item">
              <a href="#" target="_blank">{{ 'Privacy' | translate }} </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 d-none d-lg-block">
        <div class="lenovo-logo">
          <div class="w-100 h-100 d-flex justify-content-end align-items-center">
            <img src="https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/lenovo-logo.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute d-lg-none lenovo-logo">
    <div class="w-100 h-100 d-flex justify-content-end align-items-center">
      <img src="https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/lenovo-logo.png" />
    </div>
  </div>
</footer>
