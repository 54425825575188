import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { UserStoreService } from '../../../core/store/user-store.service';

@Component({
  selector: 'app-widget-main-layout',
  templateUrl: './widget-main-layout.component.html',
  styleUrls: ['./widget-main-layout.component.scss'],
})
export class WidgetMainLayoutComponent implements OnInit {
  @Input() showAccountSummaryWidget = false;
  @Input() showQuickLinks = false;
  @Input() accountSummaryWidgetFixedToScrolling = false;

  showViewWishlist = false;
  showViewCart = false;
  quickLinkRoute: string;
  quickLinkName: string;
  public termsAndConditionsUrl$ = this.userStore.termsAndConditionsUrl$;

  constructor(private location: Location, private userStore: UserStoreService) {}

  ngOnInit(): void {
    // TODO analyze which paths should really show the buttons
    if (this.location.path().includes('product-catalogue')) {
      this.showViewWishlist = true;
      this.showViewCart = true;
    } else {
      this.showViewWishlist = false;
      this.showViewCart = false;
    }

    if (this.location.path().includes('faq')) {
      this.quickLinkRoute = '/program-overview';
      this.quickLinkName = 'PROGRAM OVERVIEW';
    } else {
      this.quickLinkRoute = '/support/faq';
      this.quickLinkName = 'FAQ';
    }
  }
}
