import { Injectable } from '@angular/core';
import { AuthStoreService, ContentGqlService } from '@motivforce/mx-library-angular';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsStoreService {
  private readonly _termsAndConditionsUrl = new BehaviorSubject<string | null>(null);
  readonly termsAndConditionsUrl$ = this._termsAndConditionsUrl.asObservable();

  constructor(private contentGql: ContentGqlService, private authStore: AuthStoreService) {}

  get termsAndConditionsUrl(): string | null {
    return this._termsAndConditionsUrl.getValue();
  }

  getTermsAndConditions(): void {
    if (this.termsAndConditionsUrl) return;

    const sitePath = new URL(window.location.href);
    const slugPath = `${sitePath.origin}/terms-and-conditions-url`;

    this.authStore.userSettings$.pipe(filter(Boolean), take(1)).subscribe((userSettings) => {
      this.contentGql
        .getContentByUrlPath(slugPath, userSettings.language, true)
        .pipe(filter(Boolean), take(1))
        .subscribe((content) => {
          const urlField = content.fields.find((f: any) => f.name === 'Url');
          const tncUrl = urlField!.value.value;
          if (tncUrl) {
            // Remove html tags coming from CMS
            this._termsAndConditionsUrl.next(tncUrl.replace(/(<([^>]+)>)/gi, ''));
          }
        });
    });
  }
}
