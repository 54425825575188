import { Injectable } from '@angular/core';
import { SearchResult } from '@motivforce/mx-library-angular';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';

import { MilestoneRestService } from '../api/rest/milestone-rest.service';
import {
  MilestoneInvoice,
  InvoiceSearch,
  PerformanceMilestones,
  PerformanceMilestonesEmea,
} from '../model/core/milestone';

@Injectable({
  providedIn: 'root',
})
export class MilestoneStoreService {
  private readonly _performanceMilestones = new BehaviorSubject<
    PerformanceMilestones | PerformanceMilestonesEmea | null
  >(null);

  readonly performanceMilestones$ = this._performanceMilestones.asObservable();

  private readonly _brands = new BehaviorSubject<string[] | null>(null);
  readonly brands$ = this._brands.asObservable();

  private readonly _searchInvoiceResults = new BehaviorSubject<SearchResult<MilestoneInvoice> | null>(null);
  readonly searchInvoiceResults$ = this._searchInvoiceResults.asObservable();

  constructor(private milestoneRest: MilestoneRestService, private isLoadingService: IsLoadingService) {}

  get performanceMilestones(): PerformanceMilestones | PerformanceMilestonesEmea | null {
    return this._performanceMilestones.getValue();
  }

  get brands(): string[] | null {
    return this._brands.getValue();
  }

  getMilestones(): void {
    if (!this.performanceMilestones) {
      this.isLoadingService.add(
        this.milestoneRest.getMilestones().subscribe((performanceMilestones) => {
          // Assign milestone current value for highlighted table row
          performanceMilestones.values.forEach((milestone, index, array) => {
            const nextItem = array[index + 1];

            if (milestone.completed && !nextItem?.completed) {
              milestone.current = true;
              performanceMilestones.lastCompletedMilestone = milestone;
            } else {
              milestone.current = false;
            }
          });

          this._performanceMilestones.next(performanceMilestones);
        })
      );
    }
  }

  getBrands(): void {
    if (!this.brands) {
      this.isLoadingService.add(
        this.milestoneRest.getBrands().subscribe((brands: string[]) => {
          this._brands.next(brands);
        })
      );
    }
  }

  searchInvoice(invoiceSearch: InvoiceSearch): void {
    this.isLoadingService.add(
      this.milestoneRest.searchInvoice(invoiceSearch).subscribe((searchResult: SearchResult<MilestoneInvoice>) => {
        this._searchInvoiceResults.next(searchResult);
      })
    );
  }
}
