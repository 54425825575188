import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountSummary,
  AuthStoreService,
  MxLoggerService,
  ReferenceData,
  SearchResult,
  DialogNgbService,
  ContentGqlService,
} from '@motivforce/mx-library-angular';
import { TranslateService } from '@ngx-translate/core';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject, filter, take } from 'rxjs';

import { UploadRestService } from '../api/rest/upload-rest.service';
import { UserRestService } from '../api/rest/user-rest.service';
import { CommunicationsPreference } from '../model/core/communications-preference';
import { CreditsSummary } from '../model/core/credits-summary';
import { Currency } from '../model/core/currency';
import { Profile } from '../model/core/profile';
import { Redemption } from '../model/core/redemption';
import { RedemptionSearch } from '../model/core/redemption-search';
import { SetPassword } from '../model/core/set-password';
import { Status } from '../model/core/status';
import { SubmittedActivity } from '../model/core/submitted-activity';
import { SubmittedActivitySearch } from '../model/core/submitted-activity-search';
import { Training } from '../model/core/training';
import { TrainingSearch } from '../model/core/training-search';
import { Transaction } from '../model/core/transaction';
import { TransactionSearch } from '../model/core/transaction-search';
import { Activity } from '../model/lenovo/activity';
import { ActivityType } from '../model/lenovo/activity-type';
import { LenovoProfile } from '../model/lenovo/lenovo-profile';
import { PaymentsDetails } from '../model/lenovo/payments-details';
import { TrainingDetails } from '../model/lenovo/training-details';
import { TrainingVendor } from '../model/lenovo/training-vendor';
import { UserRole } from '../model/user/user-role';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private readonly _accountSummary = new BehaviorSubject<AccountSummary | null>(null);
  readonly accountSummary$ = this._accountSummary.asObservable();

  private readonly _yearAccountSummaries = new BehaviorSubject<AccountSummary[] | null>(null);
  readonly yearAccountSummaries$ = this._yearAccountSummaries.asObservable();

  private readonly _profile = new BehaviorSubject<Profile | null>(null);
  readonly profile$ = this._profile.asObservable();

  private readonly _lenovoProfile = new BehaviorSubject<LenovoProfile | null>(null);
  readonly lenovoProfile$ = this._lenovoProfile.asObservable();

  private readonly _currentTransactionResults = new BehaviorSubject<SearchResult<Transaction> | null>(null);
  readonly currentTransactionResults$ = this._currentTransactionResults.asObservable();

  private readonly _currentRedemptionResults = new BehaviorSubject<SearchResult<Redemption> | null>(null);
  readonly currentRedemptionResults$ = this._currentRedemptionResults.asObservable();

  private readonly _currentTrainingResults = new BehaviorSubject<SearchResult<Training> | null>(null);
  readonly currentTrainingResults$ = this._currentTrainingResults.asObservable();

  private readonly _currentActivityResults = new BehaviorSubject<SearchResult<SubmittedActivity> | null>(null);
  readonly currentActivityResults$ = this._currentActivityResults.asObservable();

  private readonly _creditsSummary = new BehaviorSubject<CreditsSummary[] | null>(null);
  readonly creditsSummary$ = this._creditsSummary.asObservable();

  private readonly _trainingStatuses = new BehaviorSubject<Status[] | null>(null);
  readonly trainingStatuses$ = this._trainingStatuses.asObservable();

  private readonly _activityStatuses = new BehaviorSubject<Status[] | null>(null);
  readonly activityStatuses$ = this._activityStatuses.asObservable();

  private readonly _transactionTypes = new BehaviorSubject<ReferenceData[] | null>(null);
  readonly transactionTypes$ = this._transactionTypes.asObservable();

  private readonly _paymentIntent = new BehaviorSubject<any | null>(null);
  readonly paymentIntent$ = this._paymentIntent.asObservable();

  private readonly _communicationPreferences = new BehaviorSubject<CommunicationsPreference | null>(null);
  readonly communicationPreferences$ = this._communicationPreferences.asObservable();

  private readonly _trainingVendors = new BehaviorSubject<TrainingVendor[] | null>(null);
  readonly trainingVendors$ = this._trainingVendors.asObservable();

  private readonly _userRole = new BehaviorSubject<UserRole | null>(null);
  readonly userRole$ = this._userRole.asObservable();

  private readonly _activityTypes = new BehaviorSubject<ActivityType[] | null>(null);
  readonly activityTypes$ = this._activityTypes.asObservable();

  private readonly _activity = new BehaviorSubject<Activity | null>(null);
  readonly activity$ = this._activity.asObservable();

  private readonly _paymentsDetailsResult = new BehaviorSubject<PaymentsDetails | null>(null);
  readonly paymentsDetailsResult$ = this._paymentsDetailsResult.asObservable();

  private readonly _trainingDetails = new BehaviorSubject<TrainingDetails | null>(null);
  readonly trainingDetails$ = this._trainingDetails.asObservable();

  private readonly _currency = new BehaviorSubject<Currency | null>(null);
  readonly currency$ = this._currency.asObservable();

  private readonly _termsAndConditionsUrl = new BehaviorSubject<string | null>(null);
  readonly termsAndConditionsUrl$ = this._termsAndConditionsUrl.asObservable();

  private readonly _emailTemplate = new BehaviorSubject<string | null>(null);
  readonly emailTemplate$ = this._emailTemplate.asObservable();

  constructor(
    private userRest: UserRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogNgbService,
    private mxLogger: MxLoggerService,
    private authStore: AuthStoreService,
    private router: Router,
    private translate: TranslateService,
    private uploadRest: UploadRestService,
    private contentGql: ContentGqlService
  ) {
    const sitePath = new URL(window.location.href);
    const termsSlugPath = `${sitePath.origin}/terms-and-conditions-url`;

    this.authStore.userSettings$.pipe(filter(Boolean)).subscribe((userSettings) => {
      this.contentGql
        .getContentByUrlPath(termsSlugPath, userSettings.language, true)
        .pipe(filter(Boolean), take(1))
        .subscribe((content) => {
          const urlField = content.fields.find((f: any) => f.name === 'Terms and Conditions Url');
          const url = urlField!.value.value;

          if (url) {
            this._termsAndConditionsUrl.next(url.replace(/(<([^>]+)>)/gi, ''));
          }
        });
    });
  }

  get accountSummary(): AccountSummary | null {
    return this._accountSummary.getValue();
  }

  get yearAccountSummaries(): AccountSummary[] | null {
    return this._yearAccountSummaries.getValue();
  }

  get profile(): Profile | null {
    return this._profile.getValue();
  }

  get lenovoProfile(): LenovoProfile | null {
    return this._lenovoProfile.getValue();
  }

  get currentTransactionResults(): SearchResult<Transaction> | null {
    return this._currentTransactionResults.getValue();
  }

  get currentRedemptionResults(): SearchResult<Redemption> | null {
    return this._currentRedemptionResults.getValue();
  }

  get currentTrainingResults(): SearchResult<Training> | null {
    return this._currentTrainingResults.getValue();
  }

  get transactionTypes(): ReferenceData[] | null {
    return this._transactionTypes.getValue();
  }

  get communicationPreferences(): CommunicationsPreference | null {
    return this._communicationPreferences.getValue();
  }

  get trainingVendors(): TrainingVendor[] | null {
    return this._trainingVendors.getValue();
  }

  get userRole(): UserRole | null {
    return this._userRole.getValue();
  }

  get activityTypes(): ActivityType[] | null {
    return this._activityTypes.getValue();
  }

  get activity(): Activity | null {
    return this._activity.getValue();
  }

  get trainingDetails(): TrainingDetails | null {
    return this._trainingDetails.getValue();
  }

  get currency(): Currency | null {
    return this._currency.getValue();
  }

  get termsAndConditionsUrl(): string | null {
    return this._termsAndConditionsUrl.getValue();
  }

  getAccountSummary(): void {
    this.isLoadingService.add(
      this.userRest.getAccountSummary().subscribe((accountSummary: AccountSummary) => {
        this._accountSummary.next(accountSummary);
      })
    );
  }

  getYearAccountSummaries(): void {
    this.isLoadingService.add(
      this.userRest.getYearAccountSummaries().subscribe((yearAccountSummaries: AccountSummary[]) => {
        this._yearAccountSummaries.next(yearAccountSummaries);
      })
    );
  }

  getProfile(): void {
    this.isLoadingService.add(
      this.userRest.getProfile().subscribe((profile: Profile) => {
        this._profile.next(profile);
      })
    );
  }

  getLenovoProfile(): void {
    this.isLoadingService.add(
      this.userRest.getLenovoProfile().subscribe((lenovoProfile: LenovoProfile) => {
        this._lenovoProfile.next(lenovoProfile);
      })
    );
  }

  updateRole(profileRoleName: string): void {
    this.isLoadingService.add(
      this.userRest.updateRole(profileRoleName).subscribe(() => {
        this.dialog.openNotification(['You have updated your role successfully.'], '');
      })
    );
  }

  getTransactionTypes(): void {
    if (this.transactionTypes) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getTransactionTypes().subscribe((transactionTypes: ReferenceData[]) => {
        this._transactionTypes.next(transactionTypes);
      })
    );
  }

  getCommunicationPreferences(): void {
    this.isLoadingService.add(
      this.userRest.getCommunicationPreferences().subscribe((communicationPreferences: CommunicationsPreference) => {
        this._communicationPreferences.next(communicationPreferences);
      })
    );
  }

  updateCommunicationPreferences(communicationPreferences: CommunicationsPreference): void {
    this.isLoadingService.add(
      this.userRest.updateCommunicationPreferences(communicationPreferences).subscribe(() => {
        this.dialog.openNotification(['You have updated your communication preferences.'], '');
      })
    );
  }

  acceptTermsConditions(): void {
    this.isLoadingService.add(
      this.userRest.acceptTermsConditions().subscribe(() => {
        this.authStore.getUserSettings();
      })
    );
  }

  setPassword(password: SetPassword): void {
    this.isLoadingService.add(
      this.userRest.setPassword(password).subscribe(() => {
        this.dialog.openNotification(['You have updated your password successfully.'], '');
        this.router.navigate(['/auth/login']);
      })
    );
  }

  createCheckoutSession(form: any): void {
    this.isLoadingService.add(
      this.userRest.createCheckoutSession(form).subscribe((paymentIntent) => {
        this._paymentIntent.next(paymentIntent);
      })
    );
  }

  getTrainingVendors(): void {
    if (this.trainingVendors) return;

    this.isLoadingService.add(
      this.userRest.getTrainingVendors().subscribe((trainingVendors) => {
        this._trainingVendors.next(trainingVendors);
      })
    );
  }

  submitTrainingPresignedUrl(file: File, boostTrainingTypeId: number): Promise<any | null> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.submitTrainingPresignedUrl(file.name, file.type, boostTrainingTypeId).subscribe({
          next: (presignedUrl) =>
            this.isLoadingService.add(
              this.uploadRest.uploadFileToS3(presignedUrl.url, file, file.type, true).subscribe({
                next: () => {
                  resolve(presignedUrl);
                },
                error: () => {
                  resolve(null);
                },
              })
            ),
          error: () => {
            resolve(null);
          },
        })
      );
    });
  }

  sendTrainingDetails(trainingDetails: TrainingDetails): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.sendTrainingDetails(trainingDetails).subscribe({
          next: () => {
            this.mxLogger.debug('UserStoreService', 'sendTrainingDetails() SUCCESS');
            resolve(true);
          },
          error: () => {
            this.mxLogger.debug('UserStoreService', 'sendTrainingDetails() ERROR');
            resolve(false);
          },
        })
      );
    });
  }

  getTrainingDetails(id: number): void {
    this.isLoadingService.add(
      this.userRest.getTrainingDetails(id).subscribe((trainingDetails: TrainingDetails) => {
        this._trainingDetails.next(trainingDetails);
      })
    );
  }

  setUserRole(settings: any): void {
    const role = settings?.user?.userRole as UserRole;
    if (role) {
      this._userRole.next(role);
    }
  }

  getPaymentsDetails(): void {
    this.isLoadingService.add(
      this.userRest.getPaymentsDetails().subscribe((paymentsDetails: PaymentsDetails) => {
        this._paymentsDetailsResult.next(paymentsDetails);
      })
    );
  }

  getActivityTypes(): void {
    this.isLoadingService.add(
      this.userRest.getActivityTypes().subscribe((activityTypes: ActivityType[]) => {
        this._activityTypes.next(activityTypes);
      })
    );
  }

  getActivity(id: number): void {
    this.isLoadingService.add(
      this.userRest.getActivity(id).subscribe((activity: Activity) => {
        this._activity.next(activity);
      })
    );
  }

  submitActivity(activity: Activity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.isLoadingService.add(
        this.userRest.submitActivity(activity).subscribe({
          next: () => {
            this.dialog.openNotification(
              [
                this.translate.instant(
                  'Thank you for your submission, you can check the status in the Activities section of My Account.'
                ),
              ],
              ''
            );
            resolve();
          },
          error: () => {
            this.dialog.openError([this.translate.instant('An error occurred.')], 'Error');
            reject();
          },
        })
      );
    });
  }

  submitActivityPresignedUrl(file: File, type: string): Promise<any | null> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.submitActivityPresignedUrl(file.name, file.type, type).subscribe({
          next: (presignedUrl) =>
            this.isLoadingService.add(
              this.uploadRest.uploadFileToS3(presignedUrl.url, file, file.type, true).subscribe({
                next: () => {
                  resolve(presignedUrl);
                },
                error: () => {
                  resolve(null);
                },
              })
            ),
          error: () => {
            resolve(null);
          },
        })
      );
    });
  }

  uploadActivityFiles(files: (File | null)[]): Promise<(any | null)[]> {
    const uploadPromises = files.map((file, index) => {
      if (file !== null) {
        const fileType = ['Objectives', 'TargetAudience', ''][index];
        return this.submitActivityPresignedUrl(file, fileType);
      }
      return Promise.resolve(null);
    });
    return Promise.all(uploadPromises);
  }

  updatePaymentDetails(paymentDetails: PaymentsDetails): void {
    this.isLoadingService.add(
      this.userRest.updatePaymentsDetails(paymentDetails).subscribe(() => {
        this.dialog.openNotification([this.translate.instant('Your bank details have been updated.')], '').then(() => {
          this.getPaymentsDetails();
        });
      })
    );
  }

  searchTransactions(searchCriteria: TransactionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchTransactions(searchCriteria).subscribe((searchResult: SearchResult<Transaction>) => {
        this._currentTransactionResults.next(searchResult);
      })
    );
  }

  searchRedemptions(searchCriteria: RedemptionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchRedemptions(searchCriteria).subscribe((searchResult: SearchResult<Redemption>) => {
        this._currentRedemptionResults.next(searchResult);
      })
    );
  }

  searchTrainings(searchCriteria: TrainingSearch): void {
    this.isLoadingService.add(
      this.userRest.searchTrainings(searchCriteria).subscribe((searchResult: SearchResult<Training>) => {
        this._currentTrainingResults.next(searchResult);
      })
    );
  }

  searchActivities(searchCriteria: SubmittedActivitySearch): void {
    this.isLoadingService.add(
      this.userRest.searchActivities(searchCriteria).subscribe((searchResult: SearchResult<SubmittedActivity>) => {
        this._currentActivityResults.next(searchResult);
      })
    );
  }

  getCreditsSummary(): void {
    this.isLoadingService.add(
      this.userRest.getCreditsSummary().subscribe((creditsSummary: CreditsSummary[]) => {
        this._creditsSummary.next(creditsSummary);
      })
    );
  }

  getTrainingStatuses(): void {
    this.isLoadingService.add(
      this.userRest.getTrainingStatuses().subscribe((statuses: Status[]) => {
        this._trainingStatuses.next(statuses);
      })
    );
  }

  getActivityStatuses(): void {
    this.isLoadingService.add(
      this.userRest.getActivityStatuses().subscribe((statuses: Status[]) => {
        this._activityStatuses.next(statuses);
      })
    );
  }

  getCurrency(): void {
    this.isLoadingService.add(
      this.userRest.getCurrency().subscribe((currency: Currency) => {
        this._currency.next(currency);
      })
    );
  }

  getEmailTemplate(entityId: string): void {
    this.isLoadingService.add(
      this.userRest.getEmailTemplate(entityId).subscribe((emailTemplate) => {
        this._emailTemplate.next(emailTemplate);
      })
    );
  }

  unsubscribeEmail(entityId: string): void {
    this.isLoadingService.add(
      this.userRest.unsubscribeEmail(entityId).subscribe(() => {
        this.dialog.openNotification(['Unsubscribed successfully'], '').then(() => {
          this.router.navigate(['/']);
        });
      })
    );
  }
}
