/* eslint-disable no-nested-ternary */
import { Component, OnInit } from '@angular/core';
import { AuthStoreService, UserSettings } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs';
import { UserPartnerType } from 'src/app/core/enum/user-partner-type.enum';
import { Banner } from 'src/app/core/model/core/banner';
import { BannerStoreService } from 'src/app/core/store/banner-store.service';

import { MilestonesMode } from '../../../core/enum/milestones-mode';
import { Currency } from '../../../core/model/core/currency';
import { Milestone, MilestoneEmea } from '../../../core/model/core/milestone';
import { User } from '../../../core/model/user';
import { MilestoneStoreService } from '../../../core/store/milestone-store.service';
import { UserStoreService } from '../../../core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  MilestonesMode = MilestonesMode;

  performance$ = this.milestoneStore.performanceMilestones$.pipe(filter(Boolean));
  currentPerformance: any;
  performanceCompletedLength = 0;
  banner: Banner;

  mode: MilestonesMode = MilestonesMode.ALL;

  mspTrackerDesktopImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/msp-tracker-desktop.png';

  mspTrackerEmeaEuroDesktopImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/msp-emea-eur-tracker-desktop.jpg';

  mspTrackerEmeaPoundDesktopImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/msp-emea-gbp-tracker-desktop.jpg';

  mspTrackerMobileImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/msp-tracker-mobile.png';

  mspTrackerEmeaEuroMobileImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/msp-emea-eur-tracker-mobile.jpg';

  mspTrackerEmeaPoundMobileImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/msp-emea-gbp-tracker-mobile.jpg';

  oemTrackerDesktopImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/oem-tracker-desktop.png';

  oemTrackerMobileImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/oem-tracker-mobile.png';

  trackerImage: string;
  trackerMobileImage: string;

  // This figures control the width per bar of progress for desktop/tablet and mobile.
  // It's multiplied depending of the completed milestone below.
  mspBarPixel = 42.8;
  mspEmeaBarPixel = 74.8;
  oemBarPixel = 33;
  mspBarMobilePixel = 55.6;
  mspEmeaBarMobilePixel = 103;
  oemBarMobilePixel = 42.5;

  barPixel = this.mspBarPixel;
  barMobilePixel = this.mspBarMobilePixel;
  barWidth = 0;
  barMobileWidth = 0;
  isOEM = false;

  currency: Currency;

  constructor(
    private userStore: UserStoreService,
    private authStore: AuthStoreService,
    private bannerStore: BannerStoreService,
    private milestoneStore: MilestoneStoreService
  ) {}

  ngOnInit(): void {
    this.milestoneStore.performanceMilestones$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe(() => this.setTrack());

    this.bannerStore.banner$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((banner) => {
      this.banner = banner;
    });

    this.authStore.isRegularUser$.pipe(untilDestroyed(this), filter(Boolean)).subscribe(() => {
      const { user } = this.authStore.userSettings as UserSettings<User>;

      this.mode = user?.region?.regionCode === 'EMEA' ? MilestonesMode.EMEA : MilestonesMode.ALL;

      this.setTrack();
    });

    this.userStore.currency$.pipe(untilDestroyed(this), filter(Boolean), take(1)).subscribe((currency) => {
      this.currency = currency;

      this.setTrack();
    });

    this.bannerStore.getBanner();
  }

  private setTrack(): void {
    if (this.milestoneStore.performanceMilestones && this.currency) {
      const data = this.milestoneStore.performanceMilestones;

      if (data.partnerType === UserPartnerType.MSP_PARTNER_TYPE) {
        this.trackerImage =
          this.mode === MilestonesMode.EMEA
            ? this.currency.isoCode === 'GBP'
              ? this.mspTrackerEmeaPoundDesktopImage
              : this.mspTrackerEmeaEuroDesktopImage
            : this.mspTrackerDesktopImage;
        this.trackerMobileImage =
          this.mode === MilestonesMode.EMEA
            ? this.currency.isoCode === 'GBP'
              ? this.mspTrackerEmeaPoundMobileImage
              : this.mspTrackerEmeaEuroMobileImage
            : this.mspTrackerMobileImage;
        this.barPixel = this.mode === MilestonesMode.EMEA ? this.mspEmeaBarPixel : this.mspBarPixel;
        this.barMobilePixel = this.mode === MilestonesMode.EMEA ? this.mspEmeaBarMobilePixel : this.mspBarMobilePixel;
      } else {
        this.trackerImage = this.oemTrackerDesktopImage;
        this.trackerMobileImage = this.oemTrackerMobileImage;
        this.barPixel = this.oemBarPixel;
        this.barMobilePixel = this.oemBarMobilePixel;
        this.isOEM = true;
      }
      const performanceCompleted = (data.values as (Milestone | MilestoneEmea)[]).filter(
        (value) => value.completed === true
      );

      this.performanceCompletedLength = performanceCompleted.length - 1;

      if (performanceCompleted) {
        this.barWidth = this.performanceCompletedLength * this.barPixel;
        this.barMobileWidth = this.performanceCompletedLength * this.barMobilePixel;
        this.currentPerformance = performanceCompleted.pop();
      }
    }
  }
}
