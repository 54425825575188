import { Component, OnInit } from '@angular/core';

import { TermsAndConditionsStoreService } from '../../../store/terms-and-conditions-store.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: string;
  public termsAndConditionsUrl$ = this.termsAndConditionsStore.termsAndConditionsUrl$;

  constructor(private termsAndConditionsStore: TermsAndConditionsStoreService) {
    this.termsAndConditionsStore.getTermsAndConditions();
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();
  }
}
