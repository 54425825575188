import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Banner, ContentStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-default-main-layout',
  templateUrl: './default-main-layout.component.html',
  styleUrls: ['./default-main-layout.component.scss'],
})
export class DefaultMainLayoutComponent implements OnInit, OnDestroy {
  private _sideWidgets: ElementRef;

  @Input() banner: Banner | null;
  @Input() showSideWidgets = false;
  @Input() showPromotionsPanel = false;
  @Input() showSubmitActivityBanner = false;
  @Input() showTitle = true;

  widgetsResizeObserver: ResizeObserver;
  widgetsHeight: number | null = null;

  constructor(private contentStore: ContentStoreService, private zone: NgZone) {}

  ngOnInit(): void {
    // in the case banner has not been provided as input
    if (this.banner == null) {
      this.contentStore.banner$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((banner) => {
        this.banner = banner;
      });
    }
  }

  @ViewChild('sideWidgets')
  get sideWidgets() {
    return this._sideWidgets;
  }

  set sideWidgets(content: ElementRef) {
    if (content) {
      this._sideWidgets = content;

      this.setResizeObserver();
    }
  }

  private setResizeObserver(): void {
    if (this.sideWidgets) {
      this.widgetsResizeObserver = new ResizeObserver((entries) => {
        this.zone.run(() => {
          this.widgetsHeight = entries[0].contentRect.height + 18 + 260;
        });
      });

      this.widgetsResizeObserver.observe(this.sideWidgets.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.widgetsResizeObserver) {
      this.widgetsResizeObserver.unobserve(this.sideWidgets.nativeElement);
    }
  }
}
