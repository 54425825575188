<div class="container banner-wrapper" *ngIf="performance$ | async as performance; else justLogo">
  <div class="row g-0">
    <div class="d-flex flex-column flex-sm-row">
      <div class="tracker-container d-none d-sm-flex">
        <div class="tracker-progress">
          <img [src]="trackerImage" />
          <div class="tracker-title">
            <div>{{ performance.businessPartnerName }}</div>
            <div class="text-bold" *ngIf="!isOEM">{{ 'Milestones Achieved' | translate }}:</div>
            <div class="text-bold" *ngIf="isOEM">{{ 'Company performance' | translate }}:</div>
          </div>
          <div
            class="progress-bar"
            [ngClass]="{ oem: isOEM, emea: mode === MilestonesMode.EMEA }"
            *ngIf="performance.values"
          >
            <div class="d-flex align-items-center">
              <div class="progress-unit" [style.width.px]="barWidth"></div>
              <div
                class="tracker-label d-flex align-items-center"
                [style.margin-left]="currentPerformance.label.length > 4 ? '-55px' : '-40px'"
                *ngIf="currentPerformance && currentPerformance.value > 0"
              >
                <div class="points me-2">{{ currentPerformance.label }}</div>
                <div
                  class="percentage"
                  [ngClass]="{
                    'ms-2': this.performanceCompletedLength > 2,
                    'ms-3': this.performanceCompletedLength <= 2
                  }"
                >
                  {{ currentPerformance.percentage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 points-container d-none d-lg-flex flex-column align-items-center justify-content-end">
          <div class="points-label">{{ 'Credit Balance' | translate }}</div>
          <div class="points-value">{{ performance.credits | number }}</div>
        </div>
      </div>
      <div class="tracker-container-mobile d-block d-sm-none">
        <div class="tracker-progress">
          <img [src]="trackerMobileImage" />
          <div class="tracker-title">
            <div>{{ performance.businessPartnerName }}</div>
            <div class="text-bold" *ngIf="!isOEM">{{ 'Milestones Achieved' | translate }}:</div>
            <div class="text-bold" *ngIf="isOEM">{{ 'Company performance' | translate }}:</div>
          </div>
          <div
            class="progress-bar"
            [ngClass]="{ oem: isOEM, emea: mode === MilestonesMode.EMEA }"
            *ngIf="performance.values"
          >
            <div class="d-flex align-items-center">
              <div class="progress-unit" [style.width.px]="barMobileWidth"></div>
              <div
                class="tracker-label d-flex align-items-center"
                *ngIf="currentPerformance && currentPerformance.value > 0"
              >
                <div class="points me-2">{{ currentPerformance.label }}</div>
                <div class="percentage ms-2">{{ currentPerformance.percentage }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-container">
        <img class="w-100 d-none d-lg-block" [src]="banner.imageUrl" />
        <div class="d-block d-lg-none">
          <div class="d-flex">
            <div class="w-50 points-container d-flex flex-column align-items-sm-center justify-content-end">
              <div class="points-label">{{ 'Credit Balance' | translate }}</div>
              <div class="points-value">{{ performance.credits | number }}</div>
            </div>
            <div
              class="w-50 banner-image d-none d-md-block d-lg-none"
              [style.background-image]="'url(' + banner.tabletImageUrl + ')'"
            ></div>
            <div
              class="w-50 banner-image d-block d-md-none"
              [style.background-image]="'url(' + banner.mobileImageUrl + ')'"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #justLogo>
  <div class="container banner-wrapper">
    <div class="w-100 banner-container d-flex justify-content-end">
      <div class="w-50 banner-image" [style.background-image]="'url(' + banner.imageUrl + ')'" *ngIf="banner"></div>
    </div>
  </div>
</ng-template>
